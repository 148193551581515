<template>
  <div class="add-new-customer">
    <title-bar
      submitBtn
      :submitBtnTitle="$t('messages.employeeCreate')"
      :title="$t('messages.employeeNew')"
      @submitPressed="submitPressed"
    />
    <employee-form :userObject="user" :action="action" @clearAction="action = null" />
  </div>
</template>

<script>
import TitleBar from '@/components/TitleBar.vue'
import EmployeeForm from './components/EmployeeForm.vue'

export default {
  components: {
    TitleBar,
    EmployeeForm
  },
  data() {
    return {
      action: null,
      user: {
        givenName: '',
        familyName: '',
        role: 'ROLE_EMPLOYEE',
        telephone: '',
        email: '',
        description: '',
        onOff: false,
        address: {
          streetAddress: '',
          city: '',
          postalCode: '',
          flatNumber: null,
          floorNumber: null,
        },
      },
    }
  },
  methods: {
    submitPressed() {
      this.action = 'add'
    }
  }
}
</script>
